import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController
);

const BarChart = ({ labels, data, chartTitle }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Label Counts",
        data: data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 15, // Decrease font size for y-axis labels
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 15, // Decrease font size for y-axis labels
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true, // Use point style for the legend (circle by default)
          pointStyle: "rectRounded", // Use rounded rectangle for the legend
          font: {
            size: 15, // Decrease font size for legend labels
          },
          boxWidth: 20, // Width of the legend color box
          padding: 20, // Padding around the legend labels
          color: "#4A4A4A", // Color for the legend text
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset) => ({
              text: dataset.label,
              fillStyle: "rgba(153, 102, 255, 0.2)", // Set the fill style for the legend color box
              strokeStyle: dataset.borderColor,
              lineWidth: dataset.borderWidth,
              index: dataset.index,
            }));
          },
        },
      },
    },
  };

  return (
    <Card>
      <Card.Header
        as="h5"
        style={{
          backgroundColor: "#001529",
          color: "white",
        }}
      >
        {chartTitle}
      </Card.Header>{" "}
      <Card.Body
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 5,
          width: "70%", // Set the width as a percentage of the Card component
          height: "70%", // Set the height as a percentage of the Card component
          margin: "auto", // This will center the Card.Body within the Card
        }}
      >
        <Bar data={chartData} options={options} />
      </Card.Body>
    </Card>
  );
};

export default BarChart;
