import React from "react";
import "../assets/Home.css";
import "../assets/Logo.css";
import "../assets/Logo.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div class="home-container">
      <div class="card-container-top">
        <div class="card-header-big">
          <h2>Metaphor Datasets Repository</h2>
        </div>
        <div class="card-content">
          <p>
            This website offers a searchable repository of open annotated
            datasets designed for metaphor studies in various disciplines.
            Specialists of metaphors have developed numerous valuable labelled
            corpora in various languages over the years. Our hope is that the
            users of this platform will be interested in sharing their datasets
            here and participate in the extension of this repository to more
            languages. Both in human sciences and in Natural Language
            Processing, researchers will benefit from a centralised database of
            labelled resources, easily accessible.
          </p>
          <p>
            <strong>
              Our project is currently restricted to textual metaphors, and does
              not contain repositories of images.
            </strong>
          </p>
        </div>
      </div>

      <div class="card-row-custom">
        <Link to="/upload" className="cardd clickable-card-custom">
          <div>
            <div class="card-header-custom">
              <h5>Upload Resources</h5>
            </div>
            <div class="card-body-custom">
              <p>
                <b>Upload new resources</b> in one of the accepted formats and
                share it with the community.
              </p>
            </div>
          </div>
        </Link>

        <Link to="/search" className="cardd clickable-card-custom">
          <div>
            <div class="card-header-custom">
              <h5>Search & Download</h5>
            </div>
            <div class="card-body-custom">
              <p>
                <b>Search for a specific dataset and download it.</b> Our
                repository is growing and contains datasets labelled for Natural
                Language processing studies!
              </p>
            </div>
          </div>
        </Link>

        <Link to="/dataset/MAGPIE" className="cardd clickable-card-custom">
          <div>
            <div class="card-header-custom">
              <h5>Dataset Stats</h5>
            </div>
            <div class="card-body-custom">
              <p>
                <b>
                  View the dataset statistics to know what you are dealing with
                </b>
              </p>
            </div>
          </div>
        </Link>
      </div>

      <div class="info-section">
        <div class="info-card">
          <span class="info-icon">📝</span>
          <span class="info-title">Authorship & Licensing</span>
          <p>
            Each dataset has one or several authors. They are often released on
            the occasion of academic publications that appear near each instance
            of the set for a search. When uploaded, they can eventually also be
            tied to a license that restricts their usage to research purposes
            only. The uploaded datasets go through a manual validation process
            before they are integrated into our database and shared online.
          </p>
        </div>

        <div class="info-card">
          <span class="info-icon">🔍</span>
          <span class="info-title">Dataset Overview</span>
          <p>
            Synthetic overviews of the datasets can be found on the Dataset
            pages. It shows the size of a dataset, the distribution of its
            metaphoric vs literal labels, the detailed information about its
            additional labels types if any (for example metaphoricity, novelty,
            emotion, concreteness…), and any optional additional information
            provided by the user who uploaded the resource, such as the genre of
            the text, the documents sources, etc...
          </p>
        </div>

        <div class="info-card">
          <span class="info-icon">🔗</span>
          <span class="info-title">Unification & Searches</span>
          <p>
            All datasets formats are constrained in order to unify them as much
            as possible, and to run textual searches on specific unified fields
            across the datasets, such as the tenor, the vehicle or broader
            context of the metaphors. This unification also allows us to
            conveniently load the datasets into machine learning models in order
            to train them to produce similar annotations.
          </p>
        </div>

        <div class="info-card">
          <span class="info-icon">🚀</span>
          <span class="info-title">Future Developments</span>
          <p>
            The unification of various datasets formats, and the integration of
            new shared resources will result in new functionalities such as
            automatically labeling metaphors in text. We also plan to extend the
            range of accepted annotation formats as soon as possible.
          </p>
        </div>
      </div>
      <div class="funding-section">
        <div class="funding-text">
          <p>
            This work has been funded by the&nbsp;
            <a href="https://www.raam.org.uk/about-raam/grants-and-funding/raam-research-grant/">
              <b>RaAM Building Bridges</b>
            </a>
            &nbsp;grant.
          </p>
        </div>
        <div>
          <img src="\logo-1.png" alt="RAAM Logo" class="funding-logo" />
        </div>
      </div>
      <div class="team-section">
        <div class="team-heading">Who are we?</div>
        <a
          href="https://www.linkedin.com/in/joanne-boisson-b5b2638b/"
          class="team-card"
        >
          <div class="team-name">Joanne Boisson</div>
          <div class="team-role">PhD Student</div>
        </a>

        <a href="https://www.linkedin.com/in/aarif9516/" class="team-card">
          <div class="team-name">Arif Mehmood</div>
          <div class="team-role">Research Software Engineer</div>
        </a>

        <a
          href="https://profiles.cardiff.ac.uk/staff/camachocolladosj"
          class="team-card"
        >
          <div class="team-name">Jose Camacho-Collados</div>
          <div class="team-role">Professor</div>
        </a>
      </div>

      <div class="contact-section">
        <div class="contact-card">
          <div class="contact-heading">Contact</div>
          <p>
            If you have questions or encounter any difficulty in using the
            website, please don’t hesitate to write to us at
          </p>
          <a href="mailto:boissonjc@cardiff.ac.uk" class="contact-email">
            boissonjc@cardiff.ac.uk
          </a>
        </div>
      </div>

      <div class="logo-container">
        <a href="https://www.cardiff.ac.uk/digital-transformation-innovation-institute">
          <img
            src="\DigitalTransformation.jpg"
            alt="Cardiff University DTII"
            class="image-style-logo  large-logo"
          />
        </a>
        <a href="https://www.cardiff.ac.uk/research/explore/research-units/natural-language-processing">
          <img src="\logo-0.png" alt="Cardiff NLP" class="image-style-logo" />
        </a>
        <a href="https://www.raam.org.uk/">
          <img src="\logo-1.png" alt="RAAM" class="image-style-logo" />
        </a>
      </div>

      {/* You can replace "path/to/cardiff_university_image.jpg" and "path/to/raam_logo.png" with the actual paths or URLs of your images */}
    </div>
  );
}

export default Home;
