import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  searchUBMI as searchUBMIApiCall,
  getUBMIDatasetsSearchableList,
  getUBMIDatasetsList,
  getDatasetDetails as getDatasetDetailsApi,
} from "./api";

const initialSearchState = {
  loading: false,
  isValidating: false,
  searchResults: { page: 1, count_on_page: 0, total_hits: 0, hits: [] },
  datasetsList: [],
  datasetsSearchableList: [],
  datasetDetails: null,
};

const searchSlice = createSlice({
  name: "search",
  initialState: initialSearchState,
  reducers: {
    searchLoading: (state, action) => {
      state.loading = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(searchUBMI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchUBMI.fulfilled, (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    });
    builder.addCase(getUBMIDatasets.fulfilled, (state, action) => {
      state.loading = false;
      state.datasetsList = action.payload;
    });
    builder.addCase(getUBMIDatasetsSearchable.fulfilled, (state, action) => {
      state.loading = false;
      state.datasetsSearchableList = action.payload;
    });
    builder.addCase(getDatasetDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDatasetDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.datasetDetails = action.payload;
    });
  },
});

export const searchUBMI = createAsyncThunk(
  "es/search",
  async (formData, thunkAPI) => {
    const response = await searchUBMIApiCall(formData);
    return response.data;
  }
);

export const getUBMIDatasets = createAsyncThunk(
  "es/dataset-names",
  async () => {
    const response = await getUBMIDatasetsList();
    return response.data;
  }
);

export const getUBMIDatasetsSearchable = createAsyncThunk(
  "es/dataset-names-searchable",
  async () => {
    const response = await getUBMIDatasetsSearchableList();
    return response.data;
  }
);

export const getDatasetDetails = createAsyncThunk(
  "es/dataset-details",
  async (formData, thunkAPI) => {
    const response = await getDatasetDetailsApi(formData);
    return response.data;
  }
);

export const { searchLoading } = searchSlice.actions;
export default searchSlice.reducer;
