import { combineReducers } from "redux";
// import entitiesReducer from "./entities";
import uploadReducer from "./upload";
import searchReducer from "./search";


export default combineReducers({
  // entities: entitiesReducer,
  upload: uploadReducer,
  search: searchReducer,
  
});