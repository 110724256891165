import React, { useState } from 'react';
import { Button, Modal } from 'antd';

// Component to handle the modal state and rendering
const AdditionalData = ({ text, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: record?.html_string }} />
      <Button size="small" onClick={showModal} style={{ marginLeft: 8 }}>
        Show Broad Context
      </Button>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* Render additional fields here */}
       {record?.additional_fields?.context?.previousSentence && <p><strong>Previous Sentence:</strong> {record?.additional_fields?.context?.previousSentence}</p>} 
        <p><strong>Current Sentence:</strong> <span dangerouslySetInnerHTML={{ __html: record?.html_string }}/></p>
       {record?.additional_fields?.context?.nextSentence && <p><strong>Next Sentence:</strong> {record?.additional_fields?.context?.nextSentence}</p>} 
      </Modal>
    </>
  );
};

export default AdditionalData;
