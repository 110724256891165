
export const getQueryParams = params => {
  const queryParams = {};

  for (let key in params) {
    if (params[key] || params[key] === 0) {
      queryParams[key] = params[key]
    }
  }
  return queryParams;
}

export const hasUndefined = params => {
  for (let key in params) {
    if (params[key] === undefined)
      return true;
  }
  return false;
}

export const hasAccess = access => {
  for (let key in access) {
    if (access[key])
      return true;
  }
  return false;
}


// Function to format the date as "DDMMYY_HHMM"
export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}${month}${year}_${hours}${minutes}`;
};

export const isExpectedError = error => {
  return (
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500
  );
}

