import { Card } from "react-bootstrap";



function BibTeXCard({ htmlContent }) {
    return (
      <Card className="mb-3">
        <Card.Header as="h5"
        style={{
          backgroundColor: "#001529",
          color: "white",
        }}>BibTeX</Card.Header>
        <Card.Body>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Card.Body>
      </Card>
    );
  }


  export default BibTeXCard;
  