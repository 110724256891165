import React from "react";
import axios from "axios";
import { formatDate } from "../../utils/helperFunctions";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { searchLoading } from "../../store/search";

const DownloadButton = ({ downloadUrl, inputParams, disable }) => {
  const dispatch = useDispatch();
  const handleDownload = async () => {
    dispatch(searchLoading(true));
    try {
      // Make the POST request with input parameters
      const response = await axios.post(
        process.env.REACT_APP_API_URL + downloadUrl,
        inputParams,
        {
          responseType: "blob", // Important for downloading files
        }
      );

      // Get the current date and time
      const now = new Date();
      const formattedDate = formatDate(now);

      // Set the file name with the formatted date
      const fileName = `search_results_${formattedDate}.csv`;

      // Create a temporary anchor element
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName || "file.csv"); // Set default file name if not found
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      link.parentNode.removeChild(link);

      dispatch(searchLoading(false));
    } catch (error) {
      console.error("Error downloading file:", error);

      dispatch(searchLoading(false));
    }
  };

  return (
    <Button
      type="primary"
      disabled={disable}
      onClick={handleDownload}
      icon={<DownloadOutlined />}
    >
      Download File
    </Button>
  );
};

export default DownloadButton;
