import "./App.css";
import FileForm from "./components/FileForm";
import Home from "./components/Home";
import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  UploadOutlined,
  SearchOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import { Routes, Route, NavLink, useLocation } from "react-router-dom";
import SearchPage from "./components/searchUBMI/SearchPage";
import Dataset from "./components/Dataset";

function App() {
  const { Header, Sider, Content } = Layout;
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  // Determine the selected keys based on the current location
  const selectedKeys =
    location.pathname === "/"
      ? ["1"]
      : location.pathname.startsWith("/upload")
      ? ["2"]
      : location.pathname.startsWith("/search")
      ? ["3"]
      : location.pathname.startsWith("/dataset")
      ? ["4"]
      : [];

  return (
    <div>
      <Layout style={{ height: "unset", minHeight: "100vh" }}>
        <Sider
          style={{ position: "sticky", top: "0", zIndex: "10" }}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="demo-logo-vertical" />
          <div
            style={{
              position: "sticky",
              top: "0",
              zIndex: "10",
              padding: collapsed ? "5px" : "30px",
              display: "flex" /* Use flexbox to center the image */,
              justifyContent: "center" /* Center horizontally */,
              alignItems: "center" /* Center vertically */,
              overflow: "hidden" /* Hide any overflow */,
            }}
          >
            <a href="https://www.cardiff.ac.uk/">
              <img
                src="\metaphor.png"
                alt="Cardiff University"
                className={`image-style ${collapsed ? "collapsed-image" : ""}`} // Toggle class based on collapsed state
              />
            </a>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            style={{
              position: "sticky",
              top: "0",
              zIndex: "10",
              overflow: "auto",
              height: "100vh",
              fontSize: "18px",
            }}
            selectedKeys={selectedKeys} // Set the dynamic selected keys
            items={[
              {
                key: "1",
                icon: <HomeOutlined />,
                label: <NavLink to="/">Home</NavLink>,
              },
              {
                key: "2",
                icon: <UploadOutlined />,
                label: <NavLink to="/upload">Upload Dataset</NavLink>,
              },
              {
                key: "3",
                icon: <SearchOutlined />,
                label: (
                  <NavLink to="/search">Search MetaphorShare Data</NavLink>
                ),
              },
              {
                key: "4",
                icon: <BarsOutlined />,
                label: <NavLink to="/dataset">Dataset Catalogue</NavLink>,
              },
            ]}
          />
        </Sider>

        <Layout style={{ height: "unset" }}>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              position: "sticky",
              top: "0",
              zIndex: "10",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              overflow: "initial",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/upload" element={<FileForm />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/dataset" element={<Dataset />} />
              <Route path="/dataset/:datasetName" element={<Dataset />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
