import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  Button,
  Form,
  Input,
  Select,
  Tag,
  Table,
  Upload,
  message,
  Row,
  Col,
  Space,
} from "antd";
import {
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  TagOutlined,
  CloudUploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import * as XLSX from "xlsx";

const Edit = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [userDataset, setUserDataset] = useState([]);
  const [currentText, setCurrentText] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [fileName, setFileName] = useState("user_dataset");
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [newTagName, setNewTagName] = useState("");
  const [newTagLetter, setNewTagLetter] = useState("");

  const [tagOptions, setTagOptions] = useState({
    m: { label: "Metaphor", color: "#69F0AE" }, // Mint Green
    l: { label: "Literal", color: "#FFA726" }, // Orange
    t: { label: "Target", color: "#80D8FF" }, // Light Cyan Blue
    a: { label: "Anomalous", color: "#FFD54F" }, // Amber
    u: { label: "Uncertain", color: "#F08080" }, // lightcoral
  });

  const [initialCustomTagColors, setInitialCustomTagColors] = useState([
    "#FF6347",
    "#8A2BE2",
    "#5F9EA0",
    "#D2691E",
    "#FF1493",
    "#00CED1",
    "#FF4500",
    "#2E8B57",
    "#DAA520",
    "#4B0082",
    "#FF69B4",
    "#CD5C5C",
    "#20B2AA",
    "#778899",
    "#BDB76B",
    "#8B4513",
    "#7FFF00",
    "#D8BFD8",
    "#FF00FF",
    "#4682B4",
  ]);

  const handleAddRow = () => {
    if (currentText && highlightedText && selectedTag) {
      let trimmedText = highlightedText.trim();
      const leadingSpace = highlightedText.startsWith(" ") ? " " : "";
      const trailingSpace = highlightedText.endsWith(" ") ? " " : "";
      const taggedText = currentText.replace(
        highlightedText,
        `${leadingSpace}<${selectedTag}>${trimmedText}</${selectedTag}>${trailingSpace}`
      );
      setCurrentText(taggedText);
      setHighlightedText("");
    }
  };

  const handleSaveRow = () => {
    if (currentText) {
      const tagPattern = /<([a-z0-9]+)>.*?<\/\1>/gi;
      if (!tagPattern.test(currentText)) {
        alert("Please ensure there is at least one tagged text in the area.");
        return;
      }

      const stack = [];
      const tagRegex = /<\/?([a-z0-9]+)>/gi;
      let match;
      while ((match = tagRegex.exec(currentText)) !== null) {
        if (match[0].startsWith("</")) {
          if (
            stack.length === 0 ||
            stack.pop().toLowerCase() !== match[1].toLowerCase()
          ) {
            alert(
              "Please ensure all tags are properly nested and there are no overlapping tags."
            );
            return;
          }
        } else {
          stack.push(match[1]);
        }
      }

      if (stack.length !== 0) {
        alert("Please ensure all beginning tags have ending tags.");
        return;
      }

      if (editIndex !== null) {
        const updatedDataset = [...userDataset];
        updatedDataset[editIndex] = currentText;
        setUserDataset(updatedDataset);
        setEditIndex(null);
      } else {
        setUserDataset([...userDataset, currentText]);
      }
      setCurrentText("");
    }
  };

  const handleEditRow = (index) => {
    const textToEdit = userDataset[index - 1] || "";
    setCurrentText(textToEdit);
    setEditIndex(index - 1);
  };

  const handleRemoveRow = (index) => {
    const updatedDataset = userDataset.filter((_, i) => i !== index - 1);
    setUserDataset(updatedDataset);
  };

  const handleHighlight = () => {
    const selection = window.getSelection();
    if (selection && selection.toString()) {
      setHighlightedText(selection.toString());
    }
  };

  const getHighlightedText = (text) => {
    const parts = text.split(/(<\/?[a-z0-9]+>)/gi);
    let isInsideTag = false;
    return parts
      .map((part, index) => {
        const tagMatch = part.match(/^<\/?([a-z0-9]+)>$/i);
        if (tagMatch) {
          const tag = tagMatch[1];
          if (part.startsWith("</")) {
            isInsideTag = false;
            return null;
          } else {
            isInsideTag = true;
            const color = tagOptions[tag.toLowerCase()]?.color || "transparent";
            return (
              <span
                key={index}
                style={{
                  backgroundColor: color,
                }}
              >
                {parts[index + 1]}
              </span>
            );
          }
        }
        return isInsideTag ? null : part.replace(/<\/?[a-z0-9]+>/gi, "");
      })
      .filter(Boolean);
  };

  const renderTaggedText = (text) => {
    const parts = text.split(/(<\/?[a-z0-9]+>)/gi);
    return parts.map((part, index) => {
      const tagMatch = part.match(/^<\/?([a-z0-9]+)>$/i);
      if (tagMatch) {
        return (
          <Tag
            key={index}
            style={{
              backgroundColor: "#e6f7ff",
              color: "#1890ff",
              cursor: "pointer",
              marginInlineEnd: 0,
            }}
          >
            {part}
          </Tag>
        );
      }
      return part;
    });
  };

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      sorter: (a, b) => a.index - b.index,
      defaultSortOrder: "descend",
      render: (text, record) => record.index + 1, // Add 1 to the index for display
    },
    {
      title: "Tagged Text",
      dataIndex: "tagged_text",
      key: "tagged_text",
      render: (text) => renderTaggedText(text),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditRow(record.index)}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveRow(record.index)}
            danger
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  const dataSource = userDataset.map((tagged_text, index) => ({
    key: index,
    index: index + 1,
    tagged_text: tagged_text,
  }));

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleUploadCSV = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension === "csv") {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const data = results.data
            .map((row) => row.tagged_text)
            .filter(Boolean);
          setUserDataset(data);
          message.success(`${file.name} file uploaded successfully`);
        },
        error: () => {
          message.error(`${file.name} file upload failed.`);
        },
      });
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headerIndex = json[0].indexOf("tagged_text");
        if (headerIndex === -1) {
          message.error(
            'The Excel file must contain a column named "tagged_text".'
          );
          return;
        }
        const dataEx = json
          .slice(1)
          .map((row) => row[headerIndex])
          .filter(Boolean);
        setUserDataset(dataEx);
        message.success(`${file.name} file uploaded successfully`);
      };
      reader.onerror = () => {
        message.error(`${file.name} file upload failed.`);
      };
      reader.readAsArrayBuffer(file);
    } else {
      message.error(
        "Unsupported file format. Please upload a CSV or Excel file."
      );
    }
    return false;
  };

  const handleAddCustomTag = () => {
    const tagLetterPattern = /^[a-z0-9]+$/i;
    const isTagNameUnique = !Object.values(tagOptions).some(
      (tag) => tag.label.toLowerCase() === newTagName.toLowerCase()
    );

    if (
      newTagName &&
      newTagLetter &&
      !tagOptions[newTagLetter.toLowerCase()] &&
      tagLetterPattern.test(newTagLetter) &&
      isTagNameUnique
    ) {
      const randomColor = initialCustomTagColors[0];
      const newTag = { label: newTagName, color: randomColor };
      setTagOptions({ ...tagOptions, [newTagLetter.toLowerCase()]: newTag });
      setInitialCustomTagColors(initialCustomTagColors.slice(1)); // Remove the used color
      setSelectedTag(newTagLetter.toLowerCase());
      setNewTagName("");
      setNewTagLetter("");
    } else {
      message.error(
        "Tag name and letter must be unique, not empty, and contain only alphanumeric characters."
      );
    }
  };

  const handleSendToFileForm = () => {
    const csvContent =
      "tagged_text\n" + userDataset.map((row) => `"${row}"`).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const file = new File([blob], `${fileName}.csv`, { type: "text/csv" });

    navigate("/upload", { state: { userDataset: [file] } });
  };

  const handleDownloadCSV = () => {
    const csvContent =
      "tagged_text\n" + userDataset.map((row) => `"${row}"`).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${fileName}.csv`);
  };

  return (
    <div className="p-4">
      <h2
        style={{
          textAlign: "center",
          backgroundColor: "#001529",
          color: "white",
          padding: "10px",
        }}
      >
        Start Working
      </h2>
      <Row gutter={16}>
        <Col span={12}>
          <div
            style={{
              border: "1px solid #d9d9d9",
              padding: "20px",
              textAlign: "center",
              height: "100%",
            }}
          >
            <h4>Start from Scratch</h4>
            <p>
              Start inputting text in the input text area, then tag the text
              content and keep adding rows.
            </p>
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              border: "1px solid #d9d9d9",
              padding: "20px",
              textAlign: "center",
              height: "100%",
            }}
          >
            <h4>Upload a table (CSV or Excel)</h4>
            <p>
              Upload your text within a table containing a column named
              "tagged_text". Your file must be in a CSV or an Excel format.
            </p>
            <Upload beforeUpload={handleUploadCSV} showUploadList={false}>
              <Button icon={<UploadOutlined />}>Upload CSV or Excel file</Button>
            </Upload>
          </div>
        </Col>
      </Row>
      <div className="p-4">
        <h2
          style={{
            textAlign: "center",
            backgroundColor: "#001529",
            color: "white",
            padding: "10px",
          }}
        >
          Create Records in a Dataset
        </h2>
        <Form layout="vertical">
          <Form.Item
            label={<span style={{ fontWeight: "bold" }}>Input text</span>}
          >
            <Input.TextArea
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
              onMouseUp={handleHighlight}
              rows={3}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontWeight: "bold" }}>Select a tag</span>}
              >
                <Select
                  value={selectedTag}
                  onChange={(value) => setSelectedTag(value)}
                  style={{ width: "100%" }}
                >
                  {Object.entries(tagOptions).map(([key, { label }]) => (
                    <Select.Option key={key} value={key}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontWeight: "bold" }}>Add Custom Tag</span>
                }
              >
                <Input
                  placeholder="Tag Name"
                  value={newTagName}
                  onChange={(e) => setNewTagName(e.target.value)}
                  style={{ marginBottom: "8px" }}
                />
                <Input
                  placeholder="Tag Letter"
                  value={newTagLetter}
                  onChange={(e) => setNewTagLetter(e.target.value)}
                  style={{ marginBottom: "8px" }}
                />
                <Button
                  icon={<TagOutlined />}
                  type="dashed"
                  onClick={handleAddCustomTag}
                >
                  Add Custom Tag
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" onClick={handleAddRow} className="mt-2">
            Tag Highlighted Text
          </Button>
          <Button
            type="primary"
            onClick={handleSaveRow}
            className="mt-2"
            style={{ marginLeft: "10px" }}
          >
            {editIndex !== null ? "Update Row" : "Add Row"}
          </Button>
        </Form>
        <div
          style={{
            marginTop: "10px",
            backgroundColor: "#f5f5f5",
            padding: "10px",
          }}
        >
          <div style={{ marginTop: "10px", fontWeight: "bold" }}>
            Preview area
          </div>
          <div style={{ marginBottom: "10px" }}>
            {Object.entries(tagOptions).map(([key, { label, color }]) => (
              <Tag key={key} color={color} style={{ marginRight: "5px" }}>
                {label}
              </Tag>
            ))}
          </div>
          {getHighlightedText(currentText)}
        </div>
        <h2
          style={{
            textAlign: "center",
            backgroundColor: "#001529",
            color: "white",
            padding: "10px",
            marginTop: "20px",
          }}
        >
          Table of Created Records
        </h2>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Form.Item
            label={<span style={{ fontWeight: "bold" }}>File Name</span>}
            style={{ marginRight: "10px", marginBottom: 0 }}
          >
            <Input
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              placeholder="Enter file name"
            />
          </Form.Item>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleDownloadCSV}
            disabled={userDataset.length === 0}
            style={{ marginRight: "10px" }}
          >
            Download CSV
          </Button>
          <Button
            type="primary"
            icon={<CloudUploadOutlined />}
            onClick={handleSendToFileForm}
            disabled={userDataset.length === 0}
          >
            Upload to MetaphorShare
          </Button>
        </div>
        <Table
          className="mt-3"
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={{
            ...pagination,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
          }}
          onChange={handleTableChange}
          sortDirections={["descend"]}
          defaultSortOrder="descend"
        />
      </div>
    </div>
  );
};

export default Edit;
