import axios from 'axios';
import { toast } from 'react-toastify';
// import UaParser from 'ua-parser-js';
// import { publicIp } from 'public-ip';
import {isExpectedError} from '../utils/helperFunctions';
// import { actions } from '../app/modules/Auth/_redux/authRedux';

// const parser = new UaParser(navigator.userAgent);
// const os = parser.getOS();
// const browser = parser.getBrowser();
// let ip = '';

// (async () => {
//   try {
//     ip = await publicIp.v4();
//   } catch (ex) {
//     console.error(ex.message)
//   }
// })();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.interceptors.request.use(
//   config => {
//     const {
//       auth: { access_token, customerId }
//     } = store.getState();

//     if (access_token) {
//       config.headers.Authorization = `Bearer ${access_token}`;
//     }

//     return config;
//   },
//   err => Promise.reject(err)
// );

axios.interceptors.response.use(null, error => {
  if (!isExpectedError(error)) {
    toast.error('An unexpected error has occured!', { containerId: 'network-error' });
  }

  // if (error.response && error.response.status === 401) {
  //   store.dispatch(actions.logout());
  //   toast.error('Login session has expired.');
  // }

  return Promise.reject(error)
})

export default axios;