import React from "react";
import { Table, Button } from "antd";
import { CSVLink } from "react-csv";

class FileTable extends React.Component {
  render() {
    const { file } = this.props;

    // Define columns for the Ant Design Table
    const columns = [
      {
        title: "File Name",
        dataIndex: "file_name",
        key: "file_name",
      },
      {
        title: "Status",
        dataIndex: "file_status",
        key: "file_status",
      },
      {
        title: "Row Number / Column Name",
        dataIndex: "row_number",
        key: "row_number",
        render: (text, record) => (record.reason ? text : 'N/A'), // Only show row number if reason is present
      },
      {
        title: "Reason for Rejection",
        dataIndex: "reason",
        key: "reason",
        render: (text) => text || 'N/A', // Show 'N/A' if reason is not present
      },
    ];

    const data = Object.entries(file.stats).map(([rowNumber, reason]) => ({
        key: `${rowNumber}`,
        file_name: file.file_name,
        file_status: file.file_status,
        row_number: rowNumber,
        reason: reason,
      }))

    const csvHeaders = columns.map((column) => ({
      label: column.title,
      key: column.dataIndex,
    }));
    const csvData = data.map((row) => ({
      file_name: row.file_name,
      file_status: row.file_status,
      row_number: row.row_number || 'N/A', // Use 'N/A' for CSV if row number is not applicable
      reason: row.reason || 'N/A', // Use 'N/A' for CSV if reason is not applicable
    }));

    return (
      <div>
        <Button type="primary" style={{ marginBottom: "10px" }}>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={"ValidationResults.csv"}
          >
            Download Results
          </CSVLink>
        </Button>
        <Table columns={columns} dataSource={data} />
      </div>
    );
  }
}

export default FileTable;
