import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";

// configureStore automatically enables us to redux dev tools and apply the middleware to call API endpoints out of the box
const store = configureStore({
  reducer,
  middleware: (defaultMiddleWare) => [...defaultMiddleWare({ thunk: true })],
});

export default store;
