import React, { useRef } from "react";
import PropTypes from "prop-types";

import "./drop-file-input.css";

import { ImageConfig } from "../../config/ImageConfig";
import uploadImg from "../../assets/cloud-upload-regular-240.png";

const DropFileInput = ({files, handleFileChange, allowedExtensions=["csv"], multiple=true}) => {
  const wrapperRef = useRef(null);

//   const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const fileName = newFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();


      if (allowedExtensions.includes(fileExtension)) {
        const filesV = Array.isArray(files) ? files : [];
        var updatedList = []
        if(multiple){
          updatedList = [...filesV, newFile];
        }else{
          updatedList = [newFile];
        }
        handleFileChange(updatedList);
      } else {
        alert(
          "Invalid file extension. Please choose a file with a valid file. Allowed formats are (csv, txt, pdf, json)"
        );
        // Optionally, you can clear the file input
        // onFileChange(fileList);
      }
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...files];
    updatedList?.splice(files?.indexOf(file), 1);
    handleFileChange(updatedList);
    if (!updatedList?.length) {
      handleFileChange(null);
    } else {
      handleFileChange(updatedList);
    }
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Drag & Drop your files here</p>
        </div>
        <input
          type="file"
          accept=".txt, .pdf, .csv, .json"
          value=""
          onChange={onFileDrop}
        />
      </div>
      {files?.length > 0 ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Ready to upload</p>
          {files?.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <img
                src={
                  ImageConfig[item.type.split("/")[1]] || ImageConfig["default"]
                }
                alt=""
              />
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{item.size} Bytes</p>
              </div>
              <span
                className="drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              >
                x
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
