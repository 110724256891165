// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  display: flex;
  justify-content: center; /* Horizontally center the logos */
  align-items: center; /* Vertically center the logos */
  gap: 20px; /* Add some padding between logos */
}

.logo-container img {
  max-width: 70%; /* Ensure the logos don't exceed their original size */
  height: auto; /* Maintain aspect ratio */
}
`, "",{"version":3,"sources":["webpack://./src/assets/Logo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,kCAAkC;EAC3D,mBAAmB,EAAE,gCAAgC;EACrD,SAAS,EAAE,mCAAmC;AAChD;;AAEA;EACE,cAAc,EAAE,sDAAsD;EACtE,YAAY,EAAE,0BAA0B;AAC1C","sourcesContent":[".logo-container {\r\n  display: flex;\r\n  justify-content: center; /* Horizontally center the logos */\r\n  align-items: center; /* Vertically center the logos */\r\n  gap: 20px; /* Add some padding between logos */\r\n}\r\n\r\n.logo-container img {\r\n  max-width: 70%; /* Ensure the logos don't exceed their original size */\r\n  height: auto; /* Maintain aspect ratio */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
