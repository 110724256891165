import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDatasetDetails, getUBMIDatasets } from "../store/search.js";
import { Card, Badge, ListGroup } from "react-bootstrap";
import BarChart from "./visualizations/BarChart.js";
import { Form, Select } from "antd";
import BibTeXCard from "./visualizations/BibTeXCard.js";

function Dataset() {
  const { Option } = Select;
  let { datasetName } = useParams();
  const [selectedDataset, setSelectedDataset] = useState("");
  const dispatch = useDispatch();
  const { loading, datasetDetails, datasetsList } = useSelector(
    (state) => state.search
  );

  const labelSet = datasetDetails?.labels?.label_set;
  const labelsCounts = datasetDetails?.labels?.labels_counts;

  // Fetch dataset list if no datasetName param is passed
  useEffect(() => {
    dispatch(getUBMIDatasets());
  }, [dispatch, datasetName]);

  // Set the selected dataset when datasetsList is available or when datasetName changes
  useEffect(() => {
    if (datasetsList.length > 0 && !datasetName) {
      setSelectedDataset(datasetsList[0].dataset_name);
    } else if (datasetName) {
      setSelectedDataset(datasetName);
    }
  }, [datasetsList, datasetName]);

  // Fetch dataset details when selectedDataset changes
  useEffect(() => {
    if (selectedDataset) {
      dispatch(getDatasetDetails({ dataset_name: selectedDataset }));
    }
  }, [dispatch, selectedDataset]);

  // Method to format keys
  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Method to render values
  const renderValue = (value, key) => {
    if (key && key === "bibtex") {
      return <BibTeXCard htmlContent={value} />;
    } else if (key && key === "date_added") {
      // Parse the date and format it to dd-mm-yyyy
      const date = new Date(value);
      const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      return formattedDate;
    } else if (typeof value === "boolean") {
      return value ? (
        <Badge bg="success">Yes</Badge>
      ) : (
        <Badge bg="danger">No</Badge>
      );
    } else if (typeof value === "object" && value !== null) {
      return renderSections(value);
    } else {
      return value;
    }
  };

  const renderArray = (array, key) => (
    <ListGroup variant="flush">
      {array.map((item, index) => (
        <ListGroup.Item key={`${key}-${index}`} className="py-2">
          {renderValue(item)}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );

  const renderSections = (data, parentKey = "") => {
    // Define an array of paper-related fields in the desired order
    const paperFieldsOrder = ["Paper", "Authors", "Year", "Additional Fields"];

    let fields = Object.entries(data)
      .map(([key, value]) => ({ key, value, formattedKey: formatKey(key) }))
      .sort((a, b) => {
        // Check if both keys are paper-related fields
        if (
          paperFieldsOrder.includes(a.formattedKey) &&
          paperFieldsOrder.includes(b.formattedKey)
        ) {
          // Sort paper-related fields based on their order defined in paperFieldsOrder
          return (
            paperFieldsOrder.indexOf(a.formattedKey) -
            paperFieldsOrder.indexOf(b.formattedKey)
          );
        } else if (paperFieldsOrder.includes(a.formattedKey)) {
          // Prioritize paper-related fields over others
          return -1;
        } else if (paperFieldsOrder.includes(b.formattedKey)) {
          // Prioritize paper-related fields over others
          return 1;
        }
        // Default alphabetical sorting for non-paper-related fields
        return a.formattedKey.localeCompare(b.formattedKey);
      });

    // Separate fields without children (topLevelFields) and with children (nestedFields)
    const topLevelFields = fields
      .filter(({ key, value }) => {
        return !Array.isArray(value) && typeof value !== "object";
      })
      .map(({ key, value, formattedKey }) => {
        return (
          <ListGroup.Item key={key} className="py-2">
            <div className="row">
              <div className="col-3 text-right">
                <strong>{formattedKey}:</strong>
              </div>
              <div className="col-9">{renderValue(value, key)}</div>
            </div>
          </ListGroup.Item>
        );
      });

    const nestedFields = fields
      .filter(({ key, value }) => {
        return (
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "object" &&
            value !== null &&
            Object.keys(value).length !== 0)
        );
      })
      .map(({ key, value, formattedKey }) => {
        if (Array.isArray(value)) {
          if (value.length === 0) return null; // Skip empty arrays
          return (
            <Card key={key} className="mb-3">
              <Card.Header
                as="h5"
                style={{ backgroundColor: "#001529", color: "white" }}
              >
                {formattedKey}
              </Card.Header>
              {renderArray(value, key)}
            </Card>
          );
        } else {
          return (
            <Card key={key} className="mb-3">
              <Card.Header
                as="h5"
                style={{ backgroundColor: "#001529", color: "white" }}
              >
                {formattedKey}
              </Card.Header>
              <ListGroup variant="flush">
                {renderSections(value, key)}
              </ListGroup>
            </Card>
          );
        }
      })
      .filter(Boolean);

    return (
      <>
        {topLevelFields}
        {nestedFields}
      </>
    );
  };

  return (
    <div className="container mt-4">
      <div className="mb-3">
        {" "}
        {/* Bootstrap class for margin-bottom */}
        <div className="row g-2">
          {" "}
          {/* Bootstrap row with gutters */}
          <div className="col">
            <Form.Item label="Select Dataset:">
              <Select
                mode="single"
                style={{ width: "100%" }}
                placeholder="Search by Dataset"
                value={selectedDataset}
                onChange={(value) => {
                  setSelectedDataset(value);
                }}
              >
                {datasetsList?.map((dataset, index) => (
                  <Option key={index} value={dataset}>
                    {dataset}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
      <h2
        style={{
          textAlign: "center",
          backgroundColor: "#001529",
          color: "white",
          padding: "10px",
        }}
      >
        Dataset Catalogue: {selectedDataset}
      </h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        datasetDetails && renderSections(datasetDetails)
      )}
      {datasetDetails?.labels && (
        <BarChart
            labels={labelSet}
            data={labelsCounts}
            chartTitle="Label Distribution"
          />
      )}
    </div>
  );
}

export default Dataset;
