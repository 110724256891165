import React, { useState } from "react";
import { Button, Modal } from "antd";

// Component to handle the modal state and rendering
const AdditionalDetailsModal = ({ record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Custom render function for the Context column
  const renderContext = () => {
    return (
      <div>
        <p><span dangerouslySetInnerHTML={{ __html: record?.html_string }} /></p>
        <p>
          Note: <span style={{ backgroundColor: "lightgreen" }}>Green</span>{" "}
          highlights the target, and{" "}
          <span style={{ backgroundColor: "yellow" }}>Yellow</span> highlights
          the expression.
        </p>
      </div>
    );
  };

  const renderContentFields = () => {
    return Object.entries(record).map(([key, value]) => {
      // Skip rendering for vectors here, handle them separately
      if (
        key === "context_vector" ||
        key === "expression_vector" ||
        key === "dataset_id" ||
        key === "targetPosition" ||
        key === "position" ||
        key === "html_string"
      ) {
        return null;
      }

      if (key.endsWith("_vector")) {
        return null;
      }

      // Check if the value is an object and render its properties
      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        return (
          <div key={key} className="mb-2">
            <strong>{key}:</strong>
            <ul className="list-group list-group-flush">
              {Object.entries(value).map(([subKey, subValue]) => (
                <li key={subKey} className="list-group-item">
                  <strong>{subKey}:</strong> {JSON.stringify(subValue, null, 2)}
                </li>
              ))}
            </ul>
          </div>
        );
      }

      // Render regular fields
      return (
        <div key={key} className="mb-2">
          <strong>{key}:</strong> {JSON.stringify(value, null, 2)}
        </div>
      );
    });
  };

  return (
    <>
      <Button size="small" onClick={showModal}>
        Show Details
      </Button>
      <Modal
        title="Additional Details"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800} // You can adjust the width as needed
      >
        <div className="card">
          <div className="card-body">
            {renderContentFields()}
            {renderContext()}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdditionalDetailsModal;
