import { Form, DatePicker, Button, Select, FloatButton } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import Input from "antd/es/input/Input";
import { Checkbox } from "antd";
import ISO6391 from "iso-639-1";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
// import { useDispatch, useSelector } from "react-redux";
import DropFileInput from "../components/drop-file-input/DropFileInput";
import ModalPage from "./ModalPage";
import * as Yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUBMIDatasets } from "../store/search";
import { Card } from "react-bootstrap";
import GuidelinesModal from "./GuidelinesModal";

const initialState = {
  email: "",
  name: "",
  message: "",
  corpus: "",
  language: ["English"],
  author: "",
  year: null,
  links: "",
  paper: "",
  otherLicense: "",
  license: "",
  typeOfAnnotators: "",
  noOfAnnotators: "",
  genre: "",
  source: [],
  bibtex: "",
  declaration: false,
  tenorPos: [],
  vehiclePos: [],
};

const partsOfSpeechOptions = [
  "Any",
  "Noun",
  "Verb",
  "Adjective",
  "Adverb",
  "Preposition",
  "Various",
];

const license_list = [
  "Apache license 2.0",
  "MIT",
  "Academic Free License v3.0",
  "Artistic license 2.0",
  "Boost Software License 1.0",
  "BSD license family",
  "BSD 2-clause “Simplified” license",
  "BSD 3-clause “New” or “Revised” license",
  "BSD 3-clause Clear license",
  "Computational Use of Data Agreement",
  "Creative Commons license family",
  "Creative Commons Zero v1.0 Universal",
  "Creative Commons Attribution 2.0",
  "Creative Commons Attribution 2.5",
  "Creative Commons Attribution 3.0",
  "Creative Commons Attribution 4.0",
  "Creative Commons Attribution Share Alike 3.0",
  "Creative Commons Attribution Share Alike 4.0",
  "Creative Commons Attribution Non Commercial 2.0",
  "Creative Commons Attribution Non Commercial 3.0",
  "Creative Commons Attribution Non Commercial 4.0",
  "Creative Commons Attribution No Derivatives 4.0",
  "Creative Commons Attribution Non Commercial No Derivatives 3.0",
  "Creative Commons Attribution Non Commercial No Derivatives 4.0",
  "Creative Commons Attribution Non Commercial Share Alike 2.0",
  "Creative Commons Attribution Non Commercial Share Alike 3.0",
  "Creative Commons Attribution Non Commercial Share Alike 4.0",
  "Community Data License Agreement – Sharing, Version 1.0",
  "Community Data License Agreement – Permissive, Version 1.0",
  "Community Data License Agreement – Permissive, Version 2.0",
  "Do What The F*ck You Want To Public License",
  "Educational Community License v2.0",
  "Eclipse Public License 1.0",
  "Eclipse Public License 2.0",
  "Etalab Open License 2.0",
  "European Union Public License 1.1",
  "GNU Affero General Public License v3.0",
  "GNU Free Documentation License family",
  "GNU General Public License family",
  "GNU General Public License v2.0",
  "GNU General Public License v3.0",
  "GNU Lesser General Public License family",
  "GNU Lesser General Public License v2.1",
  "GNU Lesser General Public License v3.0",
  "ISC",
  "LaTeX Project Public License v1.3c",
  "Microsoft Public License",
  "Mozilla Public License 2.0",
  "Open Data Commons License Attribution family",
  "Open Database License family",
  "Open Software License 3.0",
  "PostgreSQL License",
  "SIL Open Font License 1.1",
  "University of Illinois/NCSA Open Source License",
  "The Unlicense",
  "zLib License",
  "Open Data Commons Public Domain Dedication and License",
  "Lesser General Public License For Linguistic Resources",
  "Other",
];

function FileForm() {
  const [files, setFiles] = useState([]);
  const [licenseFile, setLicenseFile] = useState([]);
  const [uploadData, setUploadData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [guidelinesVisible, setGuidelinesVisible] = useState(false);

  const dispatch = useDispatch();
  const { datasetsList } = useSelector((state) => state.search);

  const validationSchema = Yup.object(
    {
      email: Yup.string().email("Invalid email format").required("Required"),
      name: Yup.string().required("Required"),
      corpus: Yup.string()
        .required("Dataset name is required")
        .notOneOf(datasetsList, "Dataset name must be unique"),
      language: Yup.array()
        .min(1, "At least one language is required")
        .required("Required"),
      license: Yup.string().required("A license selection is required"),
      otherLicense: Yup.string().when("license", {
        is: "Other",
        then: () =>
          Yup.string().required("Other license information is required"),
      }),
      files: Yup.mixed()
        .nullable()
        .test(
          "fileRequired",
          "At least one file is required",
          (value) => !!value
        ),
      declaration: Yup.boolean()
        .required("The terms must be accepted.")
        .oneOf([true], "The terms must be accepted."),
      year: Yup.date().nullable(),
      message: Yup.string(),
      author: Yup.string(),
      bibtex: Yup.string(),
      links: Yup.string().url("Invalid URL"),
      paper: Yup.string(),
      typeOfAnnotators: Yup.string(),
      noOfAnnotators: Yup.number()
        .integer("Must be an integer")
        .min(1, "Must be at least 1"),
      genre: Yup.string(),
    },
    [["license", "otherLicense"]]
  );

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      files?.forEach((file) => {
        formData.append("upload_files", file);
      });
      const licenseFilesArray = Array.isArray(licenseFile) ? licenseFile : [];
      // Append the empty array to formData
      licenseFilesArray.forEach((file) => {
        formData.append("license_files", file);
      });

      // Append form values to formData
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Call the methods as required
      setUploadData(formData);
      setModalVisible(true);
    },
  });

  useEffect(() => {
    dispatch(getUBMIDatasets());
  }, [dispatch]);

  const handleFileChange = (selectedFiles) => {
    setFiles(selectedFiles);
    formik.setFieldValue("files", selectedFiles);
  };

  const handleLicenseFileChange = (selectedFile) => {
    setLicenseFile(selectedFile);
    formik.setFieldValue("licenseFile", selectedFile);
  };

  const languages = ISO6391.getAllNames();

  const onModalClose = () => {
    setModalVisible(false);
    setFiles([]);
    setLicenseFile([]);
    formik.resetForm();
  };

  const onGuideLinesOpen = () => {
    setGuidelinesVisible(true);
  };

  const onGuideLinesClose = () => {
    setGuidelinesVisible(false);
  };

  return (
    <div className="form-container">
      <h2>Upload Dataset</h2>
      <Form layout="vertical" onFinish={formik.handleSubmit}>
        <div className="container">
          <Card
            key="personal-details"
            style={{ marginBottom: "20px" }}
            className="mb-3"
          >
            <Card.Header
              as="h5"
              style={{ backgroundColor: "#001529", color: "white" }}
            >
              Personal Details
            </Card.Header>

            <div className="row" style={{ padding: "20px" }}>
              {/* Name Field */}
              <div className="col-md-6">
                <Form.Item
                  label="Name"
                  validateStatus={formik.errors.name ? "error" : ""}
                  help={formik.errors.name || ""}
                >
                  <Input
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Form.Item>
              </div>

              {/* Email Field */}
              <div className="col-md-6">
                <Form.Item
                  label="Email"
                  validateStatus={formik.errors.email ? "error" : ""}
                  help={formik.errors.email || ""}
                >
                  <Input
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Card
            key="dataset-details"
            style={{ marginBottom: "20px" }}
            className="mb-3"
          >
            <Card.Header
              as="h5"
              style={{ backgroundColor: "#001529", color: "white" }}
            >
              Dataset Catalogue
            </Card.Header>

            <div className="row" style={{ padding: "20px" }}>
              <div className="col-md-6">
                <Form.Item
                  label="Dataset Name"
                  validateStatus={formik.errors.corpus ? "error" : ""}
                  help={formik.errors.corpus || ""}
                >
                  <Input
                    name="corpus"
                    onChange={formik.handleChange}
                    value={formik.values.corpus}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Select Dataset Language"
                  validateStatus={formik.errors.language ? "error" : ""}
                  help={formik.errors.language || ""}
                >
                  <Select
                    name="language"
                    mode="multiple"
                    onChange={(value) =>
                      formik.setFieldValue("language", value)
                    }
                    onBlur={() => formik.setFieldTouched("language", true)}
                    value={formik.values.language}
                  >
                    {languages.map((language) => (
                      <Select.Option key={language} value={language}>
                        {language}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="row" style={{ padding: "20px" }}>
              <Card
                key="dataset-details"
                style={{ marginBottom: "20px" }}
                className="mb-3"
              >
                <Card.Header
                  as="h5"
                  style={{ backgroundColor: "#001529", color: "white" }}
                >
                  Corpus Details
                </Card.Header>
                <div className="row" style={{ padding: "20px" }}>
                  <div className="col-md-6">
                    <Form.Item
                      label="Sources"
                      // validateStatus={formik.errors.source ? "error" : ""}
                      // help={formik.errors.source || ""}
                    >
                      <Select
                        name="source"
                        mode="tags"
                        onChange={(value) => formik.setFieldValue('source', value)}
                        onBlur={formik.handleBlur}
                        value={formik.values.source}
                        tokenSeparators={[',']}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Genres"
                      validateStatus={formik.errors.genre ? "error" : ""}
                      help={formik.errors.genre || ""}
                    >
                      <Input
                        name="genre"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.genre}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Card>
            </div>

            <div className="row" style={{ padding: "20px" }}>
              <Card
                key="dataset-details"
                style={{ marginBottom: "20px" }}
                className="mb-3"
              >
                <Card.Header
                  as="h5"
                  style={{ backgroundColor: "#001529", color: "white" }}
                >
                  Related Research Paper Details
                </Card.Header>

                <div className="row" style={{ padding: "20px" }}>
                  <div className="col-md-6">
                    {/* Paper Field */}
                    <Form.Item
                      label="Paper Title"
                      validateStatus={formik.errors.paper ? "error" : ""}
                      help={formik.errors.paper || ""}
                    >
                      <Input
                        name="paper"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.paper}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Authors"
                      validateStatus={formik.errors.author ? "error" : ""}
                      help={formik.errors.author || ""}
                    >
                      <Input
                        name="author"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.author}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="row" style={{ padding: "20px" }}>
                  <div className="col-md-6">
                    <Form.Item
                      label="URL Link"
                      validateStatus={formik.errors.links ? "error" : ""}
                      help={formik.errors.links || ""}
                    >
                      <Input
                        name="links"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.links}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item label="Year Published">
                      <DatePicker
                        picker="year"
                        onChange={(date) => formik.setFieldValue("year", date)}
                        disabledDate={(current) =>
                          current && current > moment().endOf("year")
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="row" style={{ padding: "20px" }}>
                  <div className="col-md-12">
                    <Form.Item
                      label="BibTex"
                      validateStatus={formik.errors.bibtex ? "error" : ""}
                      help={formik.errors.bibtex || ""}
                    >
                      <Input.TextArea
                        name="bibtex"
                        rows={8}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.bibtex}
                        placeholder={`Enter your BibTeX here...For Example:
                    @inproceedings{rakshit-flanigan-2022-figurativeqa,
                    title = "{F}igurative{QA}: A Test Benchmark for Figurativeness Comprehension for Question Answering",
                    author = "Rakshit, Geetanjali and Flanigan, Jeffrey",
                    editor = "Ghosh, Debanjan and Beigman Klebanov, Beata and Muresan, Smaranda and Feldman, Anna and Poria, Soujanya and Chakrabarty, Tuhin",
                    booktitle = "Proceedings of the 3rd Workshop on Figurative Language Processing (FLP)",
                    month = "Dec",
                    year = "2022",
                    address = "Abu Dhabi, United Arab Emirates (Hybrid)",
                    publisher = "Association for Computational Linguistics",
                    url = "https://aclanthology.org/2022.flp-1.23",
                    doi = "10.18653/v1/2022.flp-1.23",
                    pages = "160--166",
                    abstract = "Figurative language is widespread in human language (Lakoff and Johnson, 2008) posing potential challenges in NLP applications. In this paper, we investigate the effect of figurative language on the task of question answering (QA). We construct FigQA, a test set of 400 yes-no questions with figurative and non-figurative contexts, extracted from product reviews and restaurant reviews. We demonstrate that a state-of-the-art RoBERTa QA model has considerably lower performance in question answering when the contexts are figurative rather than literal, indicating a gap in current models. We propose a general method for improving the performance of QA models by converting the figurative contexts into non-figurative by prompting GPT-3, and demonstrate its effectiveness. Our results indicate a need for building QA models infused with figurative language understanding capabilities."}`}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Card>
            </div>

            <div className="row" style={{ padding: "20px" }}>
              <Card
                key="license-information"
                style={{ marginBottom: "20px" }}
                className="mb-3"
              >
                <Card.Header
                  as="h5"
                  style={{ backgroundColor: "#001529", color: "white" }}
                >
                  License Information
                </Card.Header>

                <div className="row" style={{ padding: "20px" }}>
                  <div className="col-md-6">
                    <Form.Item
                      label="License"
                      validateStatus={formik.errors.license ? "error" : ""}
                      help={formik.errors.license || ""}
                    >
                      <Select
                        name="license"
                        onChange={(value) =>
                          formik.setFieldValue("license", value)
                        }
                        onBlur={() => formik.setFieldTouched("license", true)}
                        value={formik.values.license}
                      >
                        {license_list.map((license) => (
                          <Select.Option key={license} value={license}>
                            {license}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  {formik?.values?.license === "Other" && (
                    <div className="col-md-6">
                      <Form.Item
                        label="Other License"
                        validateStatus={
                          formik.errors.otherLicense ? "error" : ""
                        }
                        help={formik.errors.otherLicense || ""}
                      >
                        <Input
                          name="otherLicense"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.otherLicense}
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>

                {formik?.values?.license === "Other" && (
                  <div className="row">
                    <div className="mt-4 d-flex flex-column align-items-center">
                      <Form.Item label="License File (Optional)">
                        <DropFileInput
                          handleFileChange={handleLicenseFileChange}
                          files={licenseFile}
                          multiple={[false]}
                          allowedExtensions={["txt", "pdf", "json"]}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </Card>

          <Card
            key="annotation-details"
            style={{ marginBottom: "20px" }}
            className="mb-3"
          >
            <Card.Header
              as="h5"
              style={{ backgroundColor: "#001529", color: "white" }}
            >
              Annotation Details
            </Card.Header>

            <div className="row" style={{ padding: "20px" }}>
              <div className="col-md-6">
                <Form.Item
                  label="Annotator Profile (Examples: Linguist, Student, Crowdsourcing, etc.)"
                  validateStatus={formik.errors.typeOfAnnotators ? "error" : ""}
                  help={formik.errors.typeOfAnnotators || ""}
                >
                  <Input
                    name="typeOfAnnotators"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.typeOfAnnotators}
                  />
                </Form.Item>
              </div>

              <div className="col-md-6">
                <Form.Item
                  label="Number of Annotators per Example"
                  validateStatus={formik.errors.noOfAnnotators ? "error" : ""}
                  help={formik.errors.noOfAnnotators || ""}
                >
                  <Input
                    name="noOfAnnotators"
                    type="number"
                    min={1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.noOfAnnotators}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row" style={{ padding: "20px" }}>
              <div className="col-md-6">
                <Form.Item
                  label="Select Vehicle parts of speech"
                  validateStatus={formik.errors.vehiclePos ? "error" : ""}
                  help={formik.errors.vehiclePos || ""}
                >
                  <Select
                    name="vehiclePos"
                    mode="multiple"
                    onChange={(value) =>
                      formik.setFieldValue("vehiclePos", value)
                    }
                    onBlur={() => formik.setFieldTouched("vehiclePos", true)}
                    value={formik.values.vehiclePos}
                  >
                    {partsOfSpeechOptions.map((partofspeech) => (
                      <Select.Option key={partofspeech} value={partofspeech}>
                        {partofspeech}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  label="Select Tenor parts of speech"
                  validateStatus={formik.errors.tenorPos ? "error" : ""}
                  help={formik.errors.tenorPos || ""}
                >
                  <Select
                    name="tenorPos"
                    mode="multiple"
                    onChange={(value) =>
                      formik.setFieldValue("tenorPos", value)
                    }
                    onBlur={() => formik.setFieldTouched("tenorPos", true)}
                    value={formik.values.tenorPos}
                  >
                    {partsOfSpeechOptions.map((partofspeech) => (
                      <Select.Option key={partofspeech} value={partofspeech}>
                        {partofspeech}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Card>

          <Card
            key="additional-comments"
            style={{ marginBottom: "20px" }}
            className="mb-3"
          >
            <Card.Header
              as="h5"
              style={{ backgroundColor: "#001529", color: "white" }}
            >
              Additional Comments
            </Card.Header>
            <div className="row" style={{ padding: "20px" }}>
              <div className="col-md-12">
                <Form.Item
                  label="If you wish to provide additional details about the dataset, fill this field."
                  validateStatus={formik.errors.message ? "error" : ""}
                  help={formik.errors.message || ""}
                >
                  <Input.TextArea
                    name="message"
                    rows={4}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    placeholder={`Enter your text here...`}
                  />
                </Form.Item>
              </div>
            </div>
          </Card>

          <Card
            key="declaration"
            style={{ marginBottom: "20px" }}
            className="mb-3"
          >
            <Card.Header
              as="h5"
              style={{ backgroundColor: "#001529", color: "white" }}
            >
              Declaration
            </Card.Header>
            <div className="row" style={{ padding: "20px" }}>
              <div className="col-md-12">
                <ul>
                  <li>
                    I give MetaphorShare the permission to share this dataset
                    online
                  </li>
                  <li>
                    This dataset does not contain sensitive personal information
                  </li>
                  <li>
                    If I am not the author of this dataset and it was previously
                    under a license, I certify that its license allows data
                    sharing
                  </li>
                </ul>
                <Form.Item
                  validateStatus={formik.errors.declaration ? "error" : ""}
                  help={formik.errors.declaration || ""}
                >
                  <Checkbox
                    type="checkbox"
                    name="declaration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.declaration}
                  >
                    I agree to the above terms and conditions.
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </Card>

          <Card
            key="upload-file"
            style={{ marginBottom: "20px" }}
            className="mb-3"
          >
            <Card.Header
              as="h5"
              style={{ backgroundColor: "#001529", color: "white" }}
            >
              Upload Dataset Files
            </Card.Header>
            <div className="row" style={{ padding: "20px" }}>
              <div className="mt-4 d-flex flex-column align-items-center">
                <Form.Item
                  label="Files"
                  validateStatus={formik.errors.files ? "error" : ""}
                  help={formik.errors.files || ""}
                >
                  <DropFileInput
                    handleFileChange={handleFileChange}
                    files={files}
                    multiple={[false]}
                  />
                </Form.Item>
              </div>
            </div>
          </Card>

          <div className="mt-4 d-flex flex-column align-items-center">
            <Button
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
      <ModalPage
        modalVisible={modalVisible}
        uploadData={uploadData}
        onModalClose={onModalClose}
      />

      <FloatButton
        icon={<FileTextOutlined />}
        description="Guidelines"
        type="primary"
        shape="square"
        onClick={onGuideLinesOpen}
        style={{
          right: 250,
          bottom: 100,
          maxHeight: "5%",
          minHeight: "5%",
          minWidth: "5%",
          maxWidth: "5%",
          animation: "glow 1s ease-in-out infinite alternate",
        }}
      />

      <GuidelinesModal
        modalVisible={guidelinesVisible}
        onModalClose={onGuideLinesClose}
      />
    </div>
  );
}

export default FileForm;
