import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uploadFile as uploadApiCall } from "./api";
import { valdiateFile as validateApiCall } from "./api";

const initialFileUploadState = {
  loading: false,
  isValidating: false,
  uploadProgress: 0,
  validationResults: null,
  savedFiles: null,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState: initialFileUploadState,
  reducers: {
    updateUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(uploadFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.loading = false;
      state.savedFiles = action.payload.saved_files;
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(validateFile.rejected, (state, action) => {
      state.isValidating = false;
    });
    builder.addCase(validateFile.fulfilled, (state, action) => {
      state.isValidating = false;
      state.validationResults = action.payload.file_stats;
    });
    builder.addCase(validateFile.pending, (state, action) => {
      state.isValidating = true;
    });
  },
});

const { updateUploadProgress } = uploadSlice.actions;

export const uploadFile = createAsyncThunk(
  "file/upload",
  async (formData, thunkAPI) => {
    const response = await uploadApiCall(formData, (progress) => {
      thunkAPI.dispatch(updateUploadProgress(progress));
    });
    return response.data;
  }
);

export const validateFile = createAsyncThunk(
  "file/validate",
  async (formData, thunkAPI) => {
    const response = await validateApiCall(formData);
    return response.data;
  }
);

export default uploadSlice.reducer;
